
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
export default defineComponent({
  components: {
    Navbar,
  },
  setup() {
    // 静态数据
    const search = true;
    const timeYear = new Date().getFullYear();
    AMapLoader.load({
      key: "b6a52c9ee81eae1f06cc4b717781f8bb", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [], // 需要使用的的插件列表
      AMapUI: {
        // 是否加载 AMapUI，缺省不加载
        version: "1.1", // AMapUI 缺省 1.1
        plugins: [], // 需要加载的 AMapUI ui插件
      },
      Loca: {
        // 是否加载 Loca， 缺省不加载
        version: "1.3.2", // Loca 版本，缺省 1.3.2
      },
    })
      .then((AMap) => {
        var map = new AMap.Map("container", {
          zoom: 16, //级别
          center: [113.743923, 23.024925], //中心点坐标
        });
        var marker = new AMap.Marker({
          position: map.getCenter(),
          icon: require("../assets/mapAddress.png"),
          offset: new AMap.Pixel(-13, -30),
        });

        marker.setMap(map);

        // 设置label标签
        marker.setLabel({
          offset: new AMap.Pixel(0, 5), //设置文本标注偏移量
          content: "<div class='info'>广东顾诚智能科技有限公司</div>", //设置文本标注内容
          direction: "bottom", //设置文本标注方位
        });
      })
      .catch((e) => {
        console.log(e);
      });

    return {
      search,
      timeYear,
    };
  },
});
