<template>
  <div class="about">
    <Navbar :search="search" />
    <div class="about_company">
      <p class="font-title">广东顾诚智能科技有限公司</p>
      <p class="font-label">三维可视化服务专业提供商</p>
      <video
        class="about_company_video"
        src="../assets/aboutus.mp4"
        autoplay
        loop
        controls
      ></video>
      <p class="font-desc">
        我们致力于通过VR和3D重建等前沿创新技术，为公众还原一个真实可交互的实景空间，打破时间和空间的限制，让信息的传递更便捷有效，沉浸式体验让用户所见即所得，全面提升用户的视觉及交互体验。
      </p>
      <p class="font-desc">
        我们力求精致到每一个步骤，让每一个客户满意；我们为各行各业提供定制化解决方案，运用专业激光扫描设备拍摄，全自动化数字建模，给用户带来一流体验。
      </p>

      <div class="about_company_work">
        <div class="about_company_work_logo">
          <img src="../assets/contact.png" />
        </div>
        <p class="work_font-title">期待您的联系！</p>
        <p class="work_font-desc">
          VR实景&nbsp;&nbsp;VR实物建模&nbsp;&nbsp;无人机航拍&nbsp;&nbsp;VR/AR应用开发
        </p>
      </div>

      <div class="about_company_tel display_flex align-items_center">
        <img src="../assets/icon-phone.png" />
        <div class="about_company_tel_detail">
          <p>咨询 <span>0769 - 8899 3721</span></p>
          <p>
            联系 <span>139 2230 4039</span
            ><span style="font-weight: 400; margin: 0"> / 肖先生</span>
          </p>
        </div>
        <a class="about_company_tel_btn" href="tel:13922304039">点击拨打</a>
      </div>

      <div class="about_company_address display_flex align-items_center">
        <img src="../assets/icon-address.png" />
        <p>广东省 东莞市 南城区 莞太路篁村段22号 福民大厦505</p>
      </div>

      <div id="container"></div>

      <p class="about_company_banner">
        Copyright © {{ timeYear }} 广东顾诚智能科技有限公司 版权所有
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
export default defineComponent({
  components: {
    Navbar,
  },
  setup() {
    // 静态数据
    const search = true;
    const timeYear = new Date().getFullYear();
    AMapLoader.load({
      key: "b6a52c9ee81eae1f06cc4b717781f8bb", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [], // 需要使用的的插件列表
      AMapUI: {
        // 是否加载 AMapUI，缺省不加载
        version: "1.1", // AMapUI 缺省 1.1
        plugins: [], // 需要加载的 AMapUI ui插件
      },
      Loca: {
        // 是否加载 Loca， 缺省不加载
        version: "1.3.2", // Loca 版本，缺省 1.3.2
      },
    })
      .then((AMap) => {
        var map = new AMap.Map("container", {
          zoom: 16, //级别
          center: [113.743923, 23.024925], //中心点坐标
        });
        var marker = new AMap.Marker({
          position: map.getCenter(),
          icon: require("../assets/mapAddress.png"),
          offset: new AMap.Pixel(-13, -30),
        });

        marker.setMap(map);

        // 设置label标签
        marker.setLabel({
          offset: new AMap.Pixel(0, 5), //设置文本标注偏移量
          content: "<div class='info'>广东顾诚智能科技有限公司</div>", //设置文本标注内容
          direction: "bottom", //设置文本标注方位
        });
      })
      .catch((e) => {
        console.log(e);
      });

    return {
      search,
      timeYear,
    };
  },
});
</script>
<style lang="scss" scoped>
.about {
  padding: 0 20px 50px 20px;
  background-color: #fff;

  .font-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .font-label {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 30px;
  }

  .font-desc {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    margin-bottom: 30px;
  }

  &_company {
    &_video {
      width: 100%;
      height: 188px;
      margin-bottom: 21px;
    }

    &_work {
      position: relative;
      padding: 29px 13px 15px 13px;
      background: #f6f7fa;
      border-radius: 4px;
      margin-bottom: 10px;

      &_logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #f6f7fa;
        position: absolute;
        left: calc((100% - 40px) / 2);
        top: -20px;
        text-align: center;
        line-height: 40px;

        > img {
          width: 18px;
          height: 18px;
        }
      }

      .work_font-title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        text-align: center;
      }

      .work_font-desc {
        font-size: 13px;
        font-weight: 400;
        color: #5c6586;
        opacity: 0.6;
        margin-top: 12px;
        text-align: center;
      }
    }

    &_tel {
      position: relative;
      background: #f6f7fa;
      border-radius: 4px;
      margin-bottom: 10px;

      > img {
        width: 20px;
        height: 20px;
        margin: 30px;
      }

      &_detail {
        font-size: 14px;
        font-weight: 400;
        color: #999999;

        > p:first-child {
          margin-bottom: 10px;
        }

        > p {
          > span {
            font-size: 15px;
            font-family: DIN-Bold;
            font-weight: bold;
            color: #222222;
            margin-left: 15px;
          }
        }
      }

      &_btn {
        position: absolute;
        display: inline-block;
        right: 0;
        top: 0;
        width: 32px;
        height: 100%;
        background: linear-gradient(0deg, #38b6ff, #319eec);
        border-radius: 15px 0px 0px 15px;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        line-height: 32px;
        letter-spacing: 2px;
        writing-mode: vertical-lr;
        writing-mode: tb-lr;
      }
    }

    &_address {
      position: relative;
      background: #f6f7fa;
      border-radius: 4px;
      margin-bottom: 10px;
      padding-right: 16px;

      > img {
        width: 20px;
        height: 20px;
        margin: 30px;
      }

      > p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
      }
    }

    &_banner {
      font-size: 12px;
      text-align: center;
      font-weight: 400;
      color: #cccccc;
      margin: 12px 0;
    }

    #container {
      width: 100%;
      height: 120px;
    }
  }
}
</style>
